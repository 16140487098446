import React from "react";
import { Box, Typography } from "@mui/material";
import ReactPlayer from "react-player";
import { Images } from "../../../../helpers/Images";

const Numbers2024 = () => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
        maxWidth: "1500px",
        margin: "auto",
      }}
    >
      <Typography
        variant={"h1"}
        sx={{
          textAlign: "center",
          fontSize: { xs: "30px", sm: "40px" },
          fontWeight: "500",
          color: "primary.darker",
          maxWidth: "700px",
          margin: "auto",
        }}
      >
        Join us on an exciting journey through the history of the{" "}
        <span style={{ color: "#c11a83" }}>We Do Challenge!</span>
      </Typography>
      <Box
        sx={{
          height: "3px",
          maxWidth: { xs: "100px", md: "150px" },
          width: "100%",
          backgroundColor: "primary.main",
          margin: "auto",
          marginTop: "10px",
          marginBottom: "30px",
        }}
      />

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ReactPlayer
          controls={true}
          url="https://www.youtube.com/watch?v=SgrJ-aZ61Og"
          loop={true}
        />
      </div>
    </Box>
  );
};

export default Numbers2024;
