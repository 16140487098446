import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GalleryPage from "./Pages/Gallery/Gallery.page";
import HomePage from "./Pages/Home/Home.page";
import ContactPage from "./Pages/Contact/Contact.page";
import AboutPage from "./Pages/About/About.page";
import NavBar from "./Components/organisms/NavBar/NavBar";
import Footer from "./Components/organisms/Footer/Footer";
import CategoriesPage from "./Pages/Categories/Categories.page";
import { Box } from "@mui/system";
import RobomissionPage from "./Pages/Categories/Details/Robomission.page";
import ResearchersPage from "./Pages/Categories/Details/Researchers.page";
import FollowlinePage from "./Pages/Categories/Details/Followline.page";
import SumoPage from "./Pages/Categories/Details/Sumo.page";
import ScientificPage from "./Pages/Categories/Details/Scientific.page";
import Count from "./Components/molecules/Count/Count";
import FirstStagePage from "./Pages/Registration/FirstStage/FirstStage.page";
import FirstStageConfirmPage from "./Pages/Registration/FirstStageConfirm/FirstStageConfirm.page";
import CheckApplicationPage from "./Pages/Registration/CheckApplication/CheckApplication.page";
import LastStagePage from "./Pages/Registration/LastStage/LastStage.page";
import PaymentResultPage from "./Pages/PaymentResult/PaymentResult.page";
import PrivacyPage from "./Pages/Privacy/Privacy.page";
import TermsPage from "./Pages/Terms/Terms.page";
import RefundPage from "./Pages/Refund/Refund.page";

const theme = createTheme({
  palette: {
    primary: {
      light: "#c11a83",
      main: "#c11a83",
      darker: "#595c61",
      tarnsparnt: "tarnsparnt",
      // contrastText: "#010102",
    },
    secondary: {
      light: "#23aef4",
      main: "#fff",
      dark: "#a4a4a3",
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <NavBar />
        {/* <BucketList /> */}
        <Box sx={{ width: "100%", flex: "1" }}>
          <Routes>
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/gallery" element={<GalleryPage />} />
            <Route exact path="/contact" element={<ContactPage />} />
            <Route exact path="/about" element={<AboutPage />} />
            <Route exact path="/payment" element={<PaymentResultPage />} />
            <Route exact path="/privacy" element={<PrivacyPage />} />
            <Route exact path="/terms" element={<TermsPage />} />
            <Route exact path="/refund" element={<RefundPage />} />
            {/* <Route exact path="/firststage" element={<FirstStagePage />} />
            <Route path="/laststage" element={<LastStagePage />} />
            <Route path="/laststage/:code" element={<LastStagePage />} />
            <Route path="/check" element={<CheckApplicationPage />} />
            <Route path="/check/:code" element={<CheckApplicationPage />} /> */}
            <Route path="/categories" element={<CategoriesPage />} />
            <Route
              path="/competition/Robomission"
              element={<RobomissionPage />}
            />
            <Route
              path="/competition/Followline"
              element={<FollowlinePage />}
            />
            <Route path="/competition/roboguard" element={<SumoPage />} />
            <Route
              path="/competition/Researchers"
              element={<ResearchersPage />}
            />
            <Route
              path="/competition/Scientific"
              element={<ScientificPage />}
            />
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </Box>
        <ToastContainer />
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
