import { Box, Button, Typography, Grid } from "@mui/material";
import React from "react";
import "./CountDown.css";
import Countdown from "react-countdown";
import Count from "../../../molecules/Count/Count";
import { Images } from "../../../../helpers/Images";
import { useNavigate } from "react-router-dom";

const CountDown = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        padding: {
          xs: "50px 20px",
          sm: "50px 60px",
          lg: "50px 120px",
          xl: "150px 120px",
        },
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${Images.wedo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "500px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography
        variant={"h1"}
        sx={{
          textAlign: "center",
          fontSize: { xs: "50px", sm: "55px" },
          fontWeight: "500",
          color: "secondary.main",
        }}
      >
        Welcome to We Do Challenge Championship
      </Typography>
      <br />

      <Typography
        variant={"h2"}
        sx={{
          textAlign: "center",
          fontSize: { xs: "20px", sm: "30px" },
          fontWeight: "500",
          marginTop: "10px",
          color: "secondary.main",
        }}
      >
        Inspiring Young Minds, Building a Better Future
      </Typography>

      {/*<div className="count">
        <Countdown
          date={"2024-09-27T24:00:00"}
          intervalDelay={0}
          renderer={(props) => (
            <Count
              days={props.days}
              hours={props.hours}
              minutes={props.minutes}
              seconds={props.seconds}
            />
          )}
        />
      </div> */}

      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          maxWidth: "1000px",
          margin: "auto",
        }}
      >
        <a
          download
          href={
            "https://drive.google.com/uc?export=download&id=1Xu_y_7mwwdNaaULSSuwD6LrOUvGYVWu0"
          }
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{
              fontSize: "17px",
              margin: "25px 10px 0",
              fontWeight: "600",
              backgroundColor: "secondary.main",
              color: "primary.main",
            }}
          >
            we do challenge agenda
          </Button>
        </a>
        <a
          download
          href={
            "https://drive.google.com/uc?export=download&id=1-BBVXiAFSvX3gf5Q0gY8ctEKDqKYfY6U"
          }
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{
              fontSize: "17px",
              margin: "25px 10px 0",
              fontWeight: "600",
              backgroundColor: "secondary.main",
              color: "primary.main",
            }}
          >
            Robo mission schedule
          </Button>
        </a>
        <a
          download
          href={
            "https://drive.google.com/uc?export=download&id=1obCnBpRAQJY6p37jcB2aytpiq1Z9d--W"
          }
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{
              fontSize: "17px",
              margin: "25px 10px 0",
              fontWeight: "600",
              backgroundColor: "secondary.main",
              color: "primary.main",
            }}
          >
            Researchers schedule
          </Button>
        </a>
        <a
          download
          href={
            "https://drive.google.com/uc?export=download&id=1owakxdpKNybHdp98L0tTGL8gAm7VBejn"
          }
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{
              fontSize: "17px",
              margin: "25px 10px 0",
              fontWeight: "600",
              backgroundColor: "secondary.main",
              color: "primary.main",
            }}
          >
            Scientific project schedule
          </Button>
        </a>
        <a
          download
          href={
            "https://drive.google.com/uc?export=download&id=1EiVhb7Zz8m_X10H6027BQqaDCrfEC8Mm"
          }
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{
              fontSize: "17px",
              margin: "25px 10px 0",
              fontWeight: "600",
              backgroundColor: "secondary.main",
              color: "primary.main",
            }}
          >
            follow line schedule
          </Button>
        </a>
        <a
          download
          href={
            "https://drive.google.com/uc?export=download&id=1ld3L_td43qUKtNa45As74TFtuwH5ytBn"
          }
        >
          <Button
            variant="contained"
            color="secondary"
            sx={{
              fontSize: "17px",
              margin: "25px 10px 0",
              fontWeight: "600",
              backgroundColor: "secondary.main",
              color: "primary.main",
            }}
          >
            Robogaurd schedule
          </Button>
        </a>
      </Box> */}
    </Box>
  );
};

export default CountDown;
